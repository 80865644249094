import 'bootstrap';
import 'slick-carousel';

// On Dom Ready
$(function () {
	// Submenu Toggle
	$(
		'#header .menu-bar ul.top-menu li.menu-item-has-children a, #services-menu'
	).on({
		mouseenter: function () {
			// over
			$('#services-menu').addClass('active');
			$('.body-wrapper').addClass('active');
			$('#header .menu-bar ul.top-menu li.menu-item-has-children a').addClass(
				'active'
			);
		},
		mouseleave: function () {
			// out
			$('#services-menu').removeClass('active');
			$('.body-wrapper').removeClass('active');
			$(
				'#header .menu-bar ul.top-menu li.menu-item-has-children a'
			).removeClass('active');
		},
	});

	// Open Search Wrapper
	$('.btn-search-open').on('click', function (e) {
		e.preventDefault();
		$('#search-wrapper').slideDown();
		$('body').addClass('noscroll');
		$('#search-wrapper .section-content input.search-keyword').focus();
	});

	// Close Search Wrapper
	$('.btn-close-search').on('click', function (e) {
		e.preventDefault();
		$('#search-wrapper').slideUp();
		$('body').removeClass('noscroll');
	});

	// Open Menu Wrapper
	$('.btn-menu-open').on('click', function (e) {
		e.preventDefault();
		$('#mobile-menu-wrapper').slideDown();
		$('body').addClass('noscroll');
	});

	// Close Menu Wrapper
	$('.btn-close-menu').on('click', function (e) {
		e.preventDefault();
		$('#mobile-menu-wrapper').slideUp();
		$('body').removeClass('noscroll');
	});

	// Trigger Footer Collapsible Menus
	$('#footer .collapse-trigger').on('click', function (e) {
		$(this).toggleClass('active');
		$(this).next().slideToggle();
	});

	// Home Banner Carousel
	$('#home-banner .the-carousel').slick({
		dots: true,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
	});

	// How Can We Help Carousel
	$('.how-can-we-help .the-carousel').slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
	});

	// Client Testimonials Carousel
	$('#client-testimonials .the-carousel').slick({
		dots: true,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
	});

	// How Can We Help Carousel
	$('#how-can-we-help .the-carousel').slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
	});

	// Team Member Profile Carousel
	$('#profile-content .the-carousel').slick({
		dots: true,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
	});

	// Team Member Profile Services Carousel
	$('#profile-services .the-carousel').slick({
		dots: false,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 2,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
		responsive: [
			{
				breakpoint: 569,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Corporate - Commercial Services Carousel
	$('#services-team .the-carousel').slick({
		dots: false,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 569,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Home Page - Meet Our Team
	$('#meet-our-team .the-carousel').slick({
		dots: false,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
	});

	// CV Uploader Selected File Change
	$('input[type="file"]#cv').on('change', function (e) {
		var fileName = e.target.files[0].name;
		$('.custom-file-upload').html(
			'<i class="fa fa-cloud-upload"></i> ' + fileName
		);
	});
});

// After page loaded completely
$(window).on('load', function () {});
